import "../scss/index.scss";
import "focus-visible";

import { Application } from "@hotwired/stimulus";

import AddToBasket from "./AddToBasket";
import AjaxForm from "./AjaxForm";
import App from "./App";
import Auth from "./Auth";
import AutoplayingCarousel from "./AutoplayingCarousel";
import AutoplayingVideo from "./AutoplayingVideo";
import BackButton from "./BackButton";
import Basket from "./Basket";
import Carousel from "./Carousel";
import CheckInButton from "./CheckInButton";
import CheckInModal from "./CheckInModal";
import DetailsOverlay from "./DetailsOverlay";
import ExpandableSection from "./ExpandableSection";
import Filter from "./Filter";
import FocusTrap from "./FocusTrap";
import HighlightsBlock from "./HighlightsBlock";
import Lineup from "./Lineup";
import LineupPromo from "./LineupPromo";
import ListView from "./ListView";
import LoadMore from "./LoadMore";
import MemoriesNav from "./MemoriesNav";
import Payment from "./Payment";
import ReaderPayment from "./ReaderPayment";
import ResaleButton from "./ResaleButton";
import ResaleModal from "./ResaleModal";
import SelectWidget from "./SelectWidget";
import SignupCheckbox from "./SignupCheckbox";
import Ticker from "./Ticker";
import VideosCarousel from "./VideosCarousel";

const Stimulus = Application.start();

Stimulus.register("addToBasket", AddToBasket);
Stimulus.register("ajaxForm", AjaxForm);
Stimulus.register("app", App);
Stimulus.register("auth", Auth);
Stimulus.register("autoplayingCarousel", AutoplayingCarousel);
Stimulus.register("autoplayingVideo", AutoplayingVideo);
Stimulus.register("backButton", BackButton);
Stimulus.register("basket", Basket);
Stimulus.register("carousel", Carousel);
Stimulus.register("checkInButton", CheckInButton);
Stimulus.register("checkInModal", CheckInModal);
Stimulus.register("detailsOverlay", DetailsOverlay);
Stimulus.register("expandableSection", ExpandableSection);
Stimulus.register("filter", Filter);
Stimulus.register("focusTrap", FocusTrap);
Stimulus.register("highlightBlock", HighlightsBlock);
Stimulus.register("lineup", Lineup);
Stimulus.register("lineupPromo", LineupPromo);
Stimulus.register("listView", ListView);
Stimulus.register("loadMore", LoadMore);
Stimulus.register("memoriesNav", MemoriesNav);
Stimulus.register("payment", Payment);
Stimulus.register("readerPayment", ReaderPayment);
Stimulus.register("resaleButton", ResaleButton);
Stimulus.register("resaleModal", ResaleModal);
Stimulus.register("selectWidget", SelectWidget);
Stimulus.register("signupCheckbox", SignupCheckbox);
Stimulus.register("ticker", Ticker);
Stimulus.register("videosCarousel", VideosCarousel);

const links = document.querySelectorAll("a");
links.forEach((el) => {
  if (el.hostname !== window.location.hostname) {
    el.setAttribute("target", "_blank");
  }
});

window.addEventListener("popstate", () => {
  // Cheap way to make back button work with filter state / overlay changes
  // These components will push items onto the stack with history.push
  // This will fire if the user hits the back button, and simply reload the window
  // Given all the routes can be server rendered, this will mean we can cater for this
  // for free, at the cost of a hard reload, which isn't too much of a loss
  window.location.reload();
});
